<template>
  <div class="col-12 mx-auto">
    <div class="card card-border-color card-border-color-secondary">
      <div class="card-header">
        <span class="text-uppercase">{{ activeTab }}</span>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <span
              :class="[
                'nav-link',
                activeTab === 'Devices to be Deployed' && 'active',
              ]"
              @click="
                () => handleSetActiveTab('Devices to be Deployed', 'transit')
              "
            >
              Devices to be Deployed
            </span>
          </li>

          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'Active Devices' && 'active']"
              @click="() => handleSetActiveTab('Active Devices', 'Assigned')"
            >
              Active Devices
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="[
                'nav-link',
                activeTab === 'Defective Devices' && 'active',
              ]"
              @click="() => handleSetActiveTab('Defective Devices', 'Damaged')"
            >
              Defective Devices
            </span>
          </li>
          <li class="nav-item">
            <span
              :class="['nav-link', activeTab === 'All Devices' && 'active']"
              @click="() => handleSetActiveTab('All Devices', 'all')"
            >
              All Devices
            </span>
          </li>
        </ul>

        <div class="py-6" v-if="loading">
          <table-skeleton> </table-skeleton>
        </div>

        <DataTable
          v-if="!showPartnersSelect && !loading"
          :columns="[
            { field: 'serial_number', label: 'Serial Number' },
            { field: 'imei', label: 'IMEI' },
            { field: 'partner', label: 'Partner', formatFn: partnerFormatter },
            {
              field: 'functional',
              label: 'Status',
              formatFn: isFunctionalFormatter,
            },
            {
              field: 'location',
              label: 'Delivery Status',
            },
            {
              field: 'assigned_at',
              label: 'Date Assigned',
              formatFn: dateTimeFormatter,
            },
            { field: 'view', label: 'Action', sortable: false },
          ]"
          :rows="filteredRouters"
          :activeTab="activeTab"
          :selectOptions="showSelect"
          :basePaginatorUrl="basePaginatorUrl"
          :totalRecords="totalRouters"
          detailsRouteName="router-details"
          @toggle-is-hidden="toggleIsHidden"
          @get-selected-rows="getSelectedRows"
          @get-routers="getRouters"
          @set-active-tab="handleSetActiveTab"
          @fetch-next-page-results="getRouters"
        />
        <div v-if="showPartnersSelect" class="card-body">
          <PartnerListAssignRoutersTable
            @toggle-is-hidden="toggleIsHidden"
            @get-routers="getRouters"
            @set-active-tab="handleSetActiveTab"
            :selectedRouters="selectedRouters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";
import PartnerListAssignRoutersTable from "@/components/Partners/ListAssignRoutersTable";

export default {
  name: "RoutersTable",
  components: { DataTable, PartnerListAssignRoutersTable },
  data() {
    return {
      activeTab: "Devices to be Deployed",
      allRouters: [],
      filteredRouters: [],
      totalRouters: null,
      basePaginatorUrl: null,
      filtered: false,
      showPartnersSelect: false,
      error: "",
      selectedRouters: null,
      loading: true,
    };
  },
  computed: {
    showSelect() {
      if (
        this.activeTab === "All Devices" ||
        this.activeTab === "Devices to be Deployed"
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getRouters(true, `v1/routers/?location=In+Transit`);
  },
  methods: {
    getSelectedRows(records) {
      this.selectedRouters = records;
    },
    async getRouters(
      clearRouters,
      routerUrl = "v1/routers/?location=In+Transit"
    ) {
      this.basePaginatorUrl = routerUrl;
      if (clearRouters) {
        this.filteredRouters = [];
      }
      await axios
        .get(routerUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.totalRouters = response.data.count;
            this.allRouters = response.data.results;
            this.filteredRouters = this.allRouters;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSetActiveTab(tabName, tabValue) {
      this.activeTab = tabName;
      if (tabValue === "all") {
        // ALL DEVICES
        this.getRouters(true, "v1/routers/?location__in=Delivered,In+Transit");
      } else if (tabValue === "Assigned") {
        // ACTIVE DEVICES
        this.getRouters(true, `v1/routers/?location=Delivered`);
      } else if (tabValue === "transit") {
        // DEVICES TO BE DEPLOYED
        this.getRouters(true, `v1/routers/?location=In+Transit`);
      } else if (tabValue === "Damaged") {
        // DEFECTIVE DEVICES
        this.getRouters(true, "v1/routers/?functional=false");
      }
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
    statusFormatter(value) {
      if (value === 1) {
        return "";
      } else {
        return "";
      }
    },
    partnerFormatter(value) {
      return value ? value.name : "-";
    },
    toggleIsHidden() {
      this.showPartnersSelect = !this.showPartnersSelect;
    },
    dateTimeFormatter(value) {
      return value ? new Date(value).toLocaleString() : "-";
    },
    isFunctionalFormatter(value) {
      return value ? "" : "";
    },
  },
};
</script>
