<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Add Router
            <div
                class="btn btn-primary float-right"
                @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <router-creation-form/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <!-- <div class="card-header">
        All Routers
        <div
          class="btn btn-primary float-right"
          @click="isHidden = !isHidden"
        >
          Add Router
        </div>
      </div> -->
      <RoutersTable/>
    </div>
  </div>
</template>
<script>
import RoutersTable from "@/components/Routers/Table";

export default {
  name: "RoutersComponent",
  components: {RoutersTable},
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>
<style></style>
